import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: 2rem;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const FlightListContainer = styled.ul`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  list-style: none;
  padding: 0;
`;

const FlightCard = styled.li`
  background-color: #2a2a2a;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const FlightDetail = styled.div`
  margin-bottom: 1rem;
`;

const FlightDetailLabel = styled.span`
  color: #888;
  font-size: 0.9rem;
`;

const FlightDetailValue = styled.span`
  display: block;
  font-size: 1.2rem;
`;

const AdContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const FlightList = () => {
  const [flights, setFlights] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        'https://ll.thespacedevs.com/2.2.0/launch/upcoming/?search=SpaceX'
      );
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const upcomingFlights = result.data.results.filter((flight) => {
        const flightDate = new Date(flight.net);
        flightDate.setHours(0, 0, 0, 0);
        return flightDate >= currentDate;
      });
      setFlights(upcomingFlights);
    };
    fetchData();
  }, []);

  return (
    <Container>
      <Heading>Upcoming SpaceX Flights</Heading>
      <FlightListContainer>
        {flights.map((flight) => (
          <FlightCard key={flight.id}>
            <FlightDetail>
              <FlightDetailLabel>Name:</FlightDetailLabel>
              <FlightDetailValue>{flight.name}</FlightDetailValue>
            </FlightDetail>
            <FlightDetail>
              <FlightDetailLabel>Date:</FlightDetailLabel>
              <FlightDetailValue>
                {new Date(flight.net).toLocaleString()}
              </FlightDetailValue>
            </FlightDetail>
            <FlightDetail>
              <FlightDetailLabel>Location:</FlightDetailLabel>
              <FlightDetailValue>
                {flight.pad.name}, {flight.pad.location.name}
              </FlightDetailValue>
            </FlightDetail>
          </FlightCard>
        ))}
      </FlightListContainer>
      <AdContainer>
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4072706463775402"
          data-ad-slot="4847027569"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </AdContainer>
    </Container>
  );
};

export default FlightList;

